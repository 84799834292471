<template>
  <section>
    <div>
      <navigation />
      <main class="main">
        <!-- Responsive Website -->
        <div class="d-none d-lg-block">
          <div class="car-area grid bg">
            <div class="container">
              <div class="nav-men">
                <div class="row">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="car-widget" style="width: 102%">
                        <div class="row">
                          <div class="col-md-4 car-sort-box">
                            <div class="car-search-form">
                              <span
                                style="
                                  color: var(--color-blue-blue-06, #207ACE);
                                  font-family:Poppins
                                  font-size: 20px;
                                  font-style: normal;
                                  font-weight: 600;
                                  line-height: normal;
                                  letter-spacing: 0.4px;
                                "
                                >Search
                              </span>
                              <form action="#">
                                <div class="form-group">
                                  <input
                                    type="text"
                                    class="form-control"
                                    style="height: 40px; border-radius: 5px"
                                    placeholder="Search"
                                    v-model="searchKey"
                                    @keydown.enter.prevent=""
                                    @input="handleInput"
                                  />
                                  <button
                                    type="search"
                                    style="margin-top: 0px"
                                    @click="searchCarsAndUpdateURL"
                                  >
                                    <i class="far fa-search"></i>
                                  </button>
                                </div>
                                <ul v-if="isOpen" class="search-results">
                                  <li
                                    v-for="(result, index) in results"
                                    :key="index"
                                    @click="handledetaillates(result.slug)"
                                  >
                                    <i class="flaticon-drive">
                                      <b> {{ result.name }}</b></i
                                    >
                                    - {{ result.brand }} -
                                    <i class="flaticon-gas-station">
                                      {{ result.bbm }}</i
                                    >
                                  </li>
                                </ul>
                              </form>
                            </div>
                          </div>

                          <!-- <div class="col-md-3 car-sort-box">
                            <span
                              style="
                                color: var(--color-blue-blue-06, #207ACE);
                                font-family:Poppins
                                font-size: 20px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: normal;
                                letter-spacing: 0.4px;
                              "
                              >Sort By
                            </span>
                            <el-select
                              v-model="selectedSortBy"
                              :options="sortByOptions"
                              placeholder="Pilih Urutan"
                              style="width: 100%"
                              filterable
                            >
                              <el-option
                                v-for="sortBy in sortByOptions"
                                :key="sortBy.value"
                                :label="sortBy.label"
                                :value="sortBy.value"
                              ></el-option>
                            </el-select>
                          </div> -->
                          <div class="col-md-4 car-sort-box">
                            <span
                              style="
                                color: var(--color-blue-blue-06, #207ACE);
                                font-family:Poppins
                                font-size: 20px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: normal;
                                letter-spacing: 0.4px;
                              "
                              >Sort By Harga</span
                            >
                            <el-select
                              v-model="selectedSortOrder"
                              :options="sortOrderOptions"
                              placeholder="Pilih Urutan"
                              style="width: 100%"
                              filterable
                            >
                              <el-option
                                v-for="sortOrder in sortOrderOptions"
                                :key="sortOrder.value"
                                :label="sortOrder.name"
                                :value="sortOrder.value"
                              ></el-option>
                            </el-select>
                          </div>

                          <div
                            class="col-md-4 car-sort-box"
                            style="margin-top: -5px"
                          >
                            <span
                              style="
                                color: white;
                                font-family:Poppins
                                font-size: 20px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: normal;
                                letter-spacing: 0.4px;
                              "
                              >Sort By</span
                            >
                            <button
                              @click="searchCarsAndUpdateURL"
                              type="button"
                              class="btn-find"
                              style="font-family: Poppins; height: 40px"
                            >
                              <span class="far fa-search"></span> Cari Mobil
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-3">
                    <div class="car-sidebar" style="padding-top: 0%">
                      <!-- Pencarian Bedasarkan Keyword -->
                      <div class="car-widget" style="background-color: #fbab44">
                        <h4 class="title-brand" style="color: white">
                          Filter Harga
                        </h4>
                        <div class="mt-5">
                          <v-row align="center">
                            <v-col>
                              <v-text-field
                                v-model="formattedRange[0]"
                                hide-details
                                single-line
                                type="text"
                                style="font-family: Poppins"
                                dense
                                outlined
                                color="white"
                                :style="{ border: 'none', height: '50px' }"
                                @input="updateMinPrice"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row align="center">
                            <v-col>
                              <v-range-slider
                                v-model="range"
                                :max="1000000000"
                                :min="0"
                                :step="1000000"
                                hide-details
                                class="align-center"
                              ></v-range-slider>
                            </v-col>
                          </v-row>
                          <v-row align="center">
                            <v-col>
                              <v-text-field
                                style="font-family: Poppins"
                                v-model="formattedRange[1]"
                                hide-details
                                single-line
                                type="text"
                                dense
                                outlined
                                :style="{
                                  border: 'none',
                                  height: '50px',
                                  color: 'white',
                                }"
                                @input="updateMaxPrice"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </div>
                      </div>
                      <!-- Pencarian Berdasarkan Brand -->
                      <div class="car-widget" style="background-color: #1f1c1c">
                        <h4 class="car-widget-title" style="color: white">
                          Brand Mobil
                        </h4>
                        <div>
                          <el-select
                            v-model="selectedKategori"
                            :options="kategoriOptions"
                            placeholder="Pilih Kategori"
                            style="width: 100%"
                            multiple
                            filterable
                          >
                            <el-option
                              v-for="kategori in kategoriOptions"
                              :key="kategori.kategori_name"
                              :label="kategori.kategori_name"
                              :value="kategori.kategori_name"
                            ></el-option>
                          </el-select>
                        </div>

                        <div class="mt-5">
                          <el-select
                            v-model="selectedMerek"
                            :options="merekOptions"
                            placeholder="Pilih Merek"
                            multiple
                            style="width: 100%"
                            filterable
                          >
                            <el-option
                              v-for="merek in merekOptions"
                              :key="merek.nama_merek"
                              :label="merek.nama_merek"
                              :value="merek.nama_merek"
                            ></el-option>
                          </el-select>
                        </div>
                        <div class="mt-5">
                          <el-select
                            style="width: 100%"
                            v-model="selectedSeat"
                            :options="seatOptions"
                            placeholder="Pilih Kapasitas"
                            multiple
                            filterable
                          >
                            <el-option
                              v-for="seat in seatOptions"
                              :key="seat.seat_number"
                              :label="seat.seat_number"
                              :value="seat.seat_number"
                            ></el-option>
                          </el-select>
                        </div>
                      </div>
                      <!-- Pencarian Berdasarkan Model -->
                      <div class="car-widget" style="background-color: #fbab44">
                        <h4 class="title-brand" style="color: white">Model</h4>

                        <div class="">
                          <el-select
                            style="width: 100%"
                            v-model="selectedTransmisi"
                            :options="transmisiOptions"
                            placeholder="Pilih Transmisi"
                            multiple
                            filterable
                          >
                            <el-option
                              v-for="transmisi in transmisiOptions"
                              :key="transmisi.transmisi_name"
                              :label="transmisi.transmisi_name"
                              :value="transmisi.transmisi_name"
                            ></el-option>
                          </el-select>
                        </div>
                        <div class="mt-5">
                          <el-select
                            style="width: 100%"
                            v-model="selectedBbm"
                            :options="bbmOptions"
                            placeholder="Pilih Jenis Mesin"
                            multiple
                            filterable
                          >
                            <el-option
                              v-for="bbm in bbmOptions"
                              :key="bbm.bbm_name"
                              :label="bbm.bbm_name"
                              :value="bbm.bbm_name"
                            ></el-option>
                          </el-select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-9">
                    <div class="row" v-if="isLoading">
                      <div class="col">
                        <v-skeleton-loader
                          class="mx-auto border mt-3"
                          width="270px"
                          type="image, article"
                        ></v-skeleton-loader>
                        <v-skeleton-loader
                          class="mx-auto border mt-3"
                          width="270px"
                          type="image, article"
                        ></v-skeleton-loader>
                        <v-skeleton-loader
                          class="mx-auto border mt-3"
                          width="270px"
                          type="image, article"
                        ></v-skeleton-loader>
                      </div>
                      <div class="col">
                        <v-skeleton-loader
                          class="mx-auto border mt-3"
                          width="270px"
                          type="image, article"
                        ></v-skeleton-loader>
                        <v-skeleton-loader
                          class="mx-auto border mt-3"
                          width="270px"
                          type="image, article"
                        ></v-skeleton-loader>
                        <v-skeleton-loader
                          class="mx-auto border mt-3"
                          width="270px"
                          type="image, article"
                        ></v-skeleton-loader>
                      </div>
                      <div class="col">
                        <v-skeleton-loader
                          class="mx-auto border mt-3"
                          width="270px"
                          type="image, article"
                        ></v-skeleton-loader>
                        <v-skeleton-loader
                          class="mx-auto border mt-3"
                          width="270px"
                          type="image, article"
                        ></v-skeleton-loader>
                        <v-skeleton-loader
                          class="mx-auto border mt-3"
                          width="270px"
                          type="image, article"
                        ></v-skeleton-loader>
                      </div>
                    </div>
                    <!-- Produk Jaja Auto -->
                    <div class="row" v-else-if="searchResults.length > 0">
                      <div
                        v-for="car in displayedResults"
                        :key="car.id"
                        class="col-md-6 col-lg-4 col-xl-4"
                      >
                        <div
                          class="wow fadeInUp"
                          data-wow-delay=".25s"
                          style="
                            box-shadow: 20px;
                            background: white;
                            border-radius: 10px;
                            padding: 10px;
                            height: auto;
                            margin-bottom: 25px;
                            box-shadow: var(--box-shadow);
                            transition: var(--transition);
                          "
                        >
                          <div class="car-img">
                            <!-- <span class="car-status status-1">Used</span> -->
                            <center>
                              <img
                                :src="car.imagePath || defaultImage"
                                @error="onImageError"
                                style="
                                  max-width: 100%;
                                  height: 170px;
                                  object-fit: cover;
                                "
                                alt="Gambar Mobil"
                              />
                            </center>
                          </div>

                          <div class="car-content">
                            <div class="car-top">
                              <h4>
                                <a
                                  style="
                                    color: black;
                                    display: inline-block;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                    max-width: 100%; /* Ubah sesuai kebutuhan */
                                    font-family: Poppins;
                                  "
                                  href="#"
                                  >{{ car.name }}</a
                                >
                              </h4>
                              <span
                                style="color: #207ace; font-family: Poppins"
                                class="car-price"
                                >Rp {{ formatPrice(car.range_harga) }}</span
                              >
                            </div>

                            <v-row no-gutters>
                              <v-col>
                                <v-chip
                                  class="mt-2"
                                  closable
                                  style="padding: 5px"
                                  color="#207ACE"
                                  text-color="#207ACE"
                                  label
                                >
                                  <span
                                    style="
                                      color: white;
                                      font-weight: 500;
                                      font-size: 14px;
                                      font-family: Poppins;
                                    "
                                    >{{ car.transmisi }} | {{ car.bbm }}</span
                                  ></v-chip
                                >
                              </v-col>
                              <v-col
                                style="
                                  display: flex;
                                  align-items: center;
                                  justify-content: flex-end;
                                "
                              >
                                <div>
                                  <li></li>
                                </div>
                              </v-col>
                            </v-row>

                            <button
                              @click="handledetaillates(car.slug)"
                              class="btn-detail"
                              style="font-family: Poppins"
                            >
                              Detail Mobil
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <div
                        style="
                          display: flex;
                          justify-content: center;
                          align-items: center;
                          flex-direction: column;
                          height: 50vh;
                        "
                      >
                        <img
                          src="../assets/img/homepage/Notfoundcar.png"
                          style="width: 500px"
                          alt="No Results Found"
                        />
                      </div>
                    </div>
                    <div class="container">
                      <div class="row justify-content-center">
                        <div class="col-12">
                          <div class="pagination-container">
                            <div class="pagination-area">
                              <div aria-label="Page navigation example">
                                <ul class="pagination justify-content-center">
                                  <li
                                    class="page-item"
                                    :class="{
                                      disabled: this.currentPage === 1,
                                    }"
                                  >
                                    <a
                                      class="page-link"
                                      href="#"
                                      aria-label="Previous"
                                      @click="prevPage"
                                    >
                                      <span aria-hidden="true">&laquo;</span>
                                    </a>
                                  </li>
                                  <li
                                    v-for="page in displayedPages"
                                    :key="page"
                                    :class="{ active: page === currentPage }"
                                  >
                                    <a
                                      class="page-link"
                                      href="#"
                                      @click="changePage(page)"
                                      >{{ page }}</a
                                    >
                                  </li>
                                  <li
                                    class="page-item"
                                    :class="{
                                      disabled: this.currentPage === totalPages,
                                    }"
                                  >
                                    <a
                                      class="page-link"
                                      href="#"
                                      aria-label="Next"
                                      @click="nextPage"
                                    >
                                      <span aria-hidden="true">&raquo;</span>
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Responsive Mobile -->
        <div class="d-lg-none">
          <div class="car-area grid bg">
            <div class="container">
              <!-- <div class="d-flex justify-content-between">
                  <div style="margin-left: 7px">
                    <h6
                      style="
                        color: var(--Color-Neutral-Neutral-08, #1f1c1c);
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                      "
                    >
                      Toyota
                    </h6>
                    <p
                      style="
                        color: var(--Color-Neutral-Neutral-06, #706d6d);
                        font-size: 8px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        letter-spacing: 0.16px;
                      "
                    >
                      Find 5 Vehicle with match keywords
                    </p>
                  </div>
                  <div></div>
                  <div>
                    <button
                      style="
                        display: flex;
                        width: 100%;
                        padding: 5px;
                        justify-content: center;
                        align-items: center;
                        gap: 10px;
                        flex-shrink: 0;
                        align-self: stretch;
                        border-radius: 5px;
                        background: var(--Color-Blue-Blue-05, #207ace);
                      "
                    >
                      <span
                        style="
                          color: var(--Color-Neutral-Neutral-00, #fff);
                          font-size: 12px;
                          font-style: normal;
                          font-weight: 400;
                          line-height: normal;
                        "
                      >
                        Change</span
                      >
                    </button>
                  </div>
                </div> -->

              <div class="row">
                <div class="col-6">
                  <button @click="openFilterModal" class="filter-button">
                    <span
                      style="
                        color: var(--Color-Blue-Blue-05, #207ace);
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                        letter-spacing: 0.24px;
                        font-family: Poppins;
                      "
                    >
                      <img
                        src="../assets/img/search/b.png"
                        style="width: 12px; height: 10px"
                        alt=""
                      />
                      Fiter</span
                    >
                  </button>
                </div>
                <div class="col-6">
                  <button @click="openSortByModal" class="sort-button">
                    <span
                      style="
                        color: var(--Color-Blue-Blue-05, #207ace);
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                        letter-spacing: 0.24px;
                        font-family: Poppins;
                      "
                    >
                      <img
                        src="../assets/img/search/b.png"
                        style="width: 12px; height: 10px"
                        alt=""
                      />
                      Sort By</span
                    >
                  </button>
                </div>
              </div>
              <!-- Filter Modal -->
              <div class="modal" :class="{ 'is-active': isFilterModalActive }">
                <div class="modal-background" @click="closeFilterModal"></div>
                <div class="modal-content">
                  <!-- Pencarian Berdasarkan Brand -->
                  <div class="car-widget" style="background-color: white">
                    <h6 class="car-widget-title" style="color: black">
                      Filter
                    </h6>
                    <div>
                      <v-row align="center">
                        <v-col>
                          <v-range-slider
                            v-model="range"
                            :max="1000000000"
                            :min="0"
                            :step="1"
                            hide-details
                            class="align-center"
                            color="black"
                          ></v-range-slider>
                        </v-col>
                      </v-row>
                      <v-row align="center">
                        <v-col>
                          <v-text-field
                            v-model="formattedRange[0]"
                            hide-details
                            single-line
                            type="text"
                            dense
                            outlined
                            :style="{
                              border: 'none',
                              height: '50px',
                              color: 'white',
                              fontSize: '14px',
                            }"
                            @input="updateMinPrice"
                          ></v-text-field>
                        </v-col>
                        <v-col>
                          <v-text-field
                            v-model="formattedRange[1]"
                            hide-details
                            single-line
                            type="text"
                            dense
                            outlined
                            :style="{
                              border: 'none',
                              height: '50px',
                              color: 'white',
                              fontSize: '14px',
                            }"
                            @input="updateMaxPrice"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </div>

                    <div class="mt-2">
                      <el-select
                        v-model="selectedKategori"
                        :options="kategoriOptions"
                        placeholder="Pilih Kategori"
                        style="
                          width: 100%;
                          border: 1px solid black;
                          border-radius: 5px;
                        "
                        multiple
                        filterable
                      >
                        <el-option
                          v-for="kategori in kategoriOptions"
                          :key="kategori.value"
                          :label="kategori.label"
                          :value="kategori.label"
                        ></el-option>
                      </el-select>
                    </div>
                    <div class="mt-2">
                      <el-select
                        v-model="selectedMerek"
                        :options="merekOptions"
                        placeholder="Pilih Merek"
                        multiple
                        style="
                          width: 100%;
                          border: 1px solid black;
                          border-radius: 5px;
                        "
                        filterable
                      >
                        <el-option
                          v-for="merek in merekOptions"
                          :key="merek.value"
                          :label="merek.label"
                          :value="merek.value"
                        ></el-option>
                      </el-select>
                    </div>
                    <div class="mt-2">
                      <el-select
                        style="
                          width: 100%;
                          border: 1px solid black;
                          border-radius: 5px;
                        "
                        v-model="selectedSeat"
                        :options="seatOptions"
                        placeholder="Pilih Kapasitas"
                        multiple
                        filterable
                      >
                        <el-option
                          v-for="seat in seatOptions"
                          :key="seat.value"
                          :label="seat.label"
                          :value="seat.value"
                        ></el-option>
                      </el-select>
                    </div>
                    <div class="mt-2">
                      <el-select
                        style="
                          width: 100%;
                          border: 1px solid black;
                          border-radius: 5px;
                        "
                        v-model="selectedTransmisi"
                        :options="transmisiOptions"
                        placeholder="Pilih Transmisi"
                        multiple
                        filterable
                      >
                        <el-option
                          v-for="transmisi in transmisiOptions"
                          :key="transmisi.value"
                          :label="transmisi.label"
                          :value="transmisi.value"
                        ></el-option>
                      </el-select>
                    </div>
                    <div class="mt-2">
                      <el-select
                        style="
                          width: 100%;
                          border: 1px solid black;
                          border-radius: 5px;
                        "
                        v-model="selectedBbm"
                        :options="bbmOptions"
                        placeholder="Pilih Jenis Mesin"
                        multiple
                        filterable
                      >
                        <el-option
                          v-for="bbm in bbmOptions"
                          :key="bbm.value"
                          :label="bbm.label"
                          :value="bbm.value"
                        ></el-option>
                      </el-select>
                    </div>
                    <div class="mt-2 row">
                      <div class="col">
                        <button
                          @click="closeFilterModal"
                          type="button"
                          class="btn-find"
                          style="
                            background-color: white;
                            color: black;
                            height: 45px;
                            font-family: Poppins;
                            font-size: 14px;
                            border: 1px solid black;
                          "
                        >
                          Cancel
                        </button>
                      </div>
                      <div class="col">
                        <button
                          @click="searchCarsAndUpdateURL"
                          type="button"
                          class="btn-find"
                          style="
                            height: 45px;
                            font-family: Poppins;
                            font-size: 14px;
                          "
                        >
                          <span class="far fa-search"></span> Cari Mobil
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  class="modal-close is-large"
                  aria-label="close"
                  style="
                    background-color: white;
                    color: black;
                    height: 45px;
                    font-family: Poppins;
                    font-size: 14px;
                    border: 1px solid black;
                  "
                  @click="closeFilterModal"
                ></button>
              </div>

              <!-- Sort By Modal -->
              <div class="modal" :class="{ 'is-active': isSortByModalActive }">
                <div class="modal-background" @click="closeSortByModal"></div>
                <div class="modal-content">
                  <div class="car-widget" style="background-color: white">
                    <h6 class="car-widget-title" style="color: black">
                      Sort By
                    </h6>
                    <div>
                      <el-select
                        v-model="selectedSortBy"
                        :options="sortByOptions"
                        placeholder="Pilih Urutan"
                        style="
                          width: 100%;
                          border: 1px solid black;
                          border-radius: 5px;
                        "
                        filterable
                      >
                        <el-option
                          v-for="sortBy in sortByOptions"
                          :key="sortBy.value"
                          :label="sortBy.label"
                          :value="sortBy.value"
                        ></el-option>
                      </el-select>
                    </div>
                    <div class="mt-2">
                      <el-select
                        v-model="selectedSortOrder"
                        :options="sortOrderOptions"
                        placeholder="Pilih Urutan"
                        style="
                          width: 100%;
                          border: 1px solid black;
                          border-radius: 5px;
                        "
                        filterable
                      >
                        <el-option
                          v-for="sortOrder in sortOrderOptions"
                          :key="sortOrder.value"
                          :label="sortOrder.name"
                          :value="sortOrder.value"
                        ></el-option>
                      </el-select>
                    </div>
                    <div class="mt-2 row">
                      <div class="col">
                        <button
                          @click="closeSortByModal"
                          type="button"
                          class="btn-find"
                          style="
                            background-color: white;
                            color: black;
                            height: 45px;
                            font-family: Poppins;
                            font-size: 14px;
                            border: 1px solid black;
                          "
                        >
                          Close
                        </button>
                      </div>
                      <div class="col">
                        <button
                          @click="searchCarsAndUpdateURL"
                          type="button"
                          class="btn-find"
                          style="
                            height: 45px;
                            font-family: Poppins;
                            font-size: 14px;
                          "
                        >
                          <span class="far fa-search"></span> Cari Mobil
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  class="modal-close is-large"
                  aria-label="close"
                  @click="closeSortByModal"
                ></button>
              </div>

              <!-- Produk Jaja Auto -->
              <div
                style="padding-left: 5px"
                class="row"
                v-if="searchResults.length > 0"
              >
                <div
                  v-for="car in displayedResults"
                  :key="car.id"
                  class="col-6"
                >
                  <div
                    @click="handledetaillates(car.slug)"
                    class="wow fadeInUp"
                    data-wow-delay=".25s"
                    style="
                      display: flex;
                      padding: 12px 6px;
                      flex-direction: column;
                      align-items: flex-start;
                      gap: 6px;
                      flex-shrink: 0;
                      border-radius: 4px;
                      background: var(--Color-Neutral-Neutral-00, #fff);
                      box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.15);
                    "
                  >
                    <div class="car-img">
                      <!-- <span class="car-status status-1">Used</span> -->
                      <center>
                        <img
                          :src="car.imagePath"
                          style="height: 80px; max-width: 120px"
                          alt=""
                        />
                      </center>
                    </div>

                    <div class="car-content">
                      <div class="car-top">
                        <h4>
                          <a
                            style="
                              color: #000;
                              font-size: 11px;
                              font-style: normal;
                              font-weight: 500;
                              line-height: normal;
                              letter-spacing: 0.22px;
                              font-family: Poppins;
                            "
                            href="#"
                            >{{ car.name }}</a
                          >
                        </h4>
                        <span
                          style="
                            color: var(--Color-Blue-Blue-05, #207ace);
                            font-size: 11px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                            letter-spacing: 0.22px;
                            font-family: Poppins;
                          "
                          >Rp {{ formatPrice(car.rangeHarga) }}</span
                        >
                      </div>
                      <v-row no-gutters>
                        <v-col>
                          <v-chip
                            class="mt-2"
                            closable
                            style="padding: 10px; height: 20px"
                            color="#207ACE"
                            text-color="#207ACE"
                            label
                          >
                            <span
                              style="
                                color: white;
                                font-weight: 300;
                                font-size: 10px;
                                font-family: Poppins;
                              "
                              >{{ car.transmisi }} | {{ car.bbm }}</span
                            ></v-chip
                          >
                        </v-col>
                        <v-col
                          style="
                            display: flex;
                            align-items: center;
                            justify-content: flex-end;
                          "
                        >
                          <div>
                            <li></li>
                          </div>
                        </v-col>
                      </v-row>
                    </div>
                  </div>
                </div>
              </div>

              <div v-else>
                <div
                  style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    height: 50vh;
                  "
                >
                  <img
                    src="../assets/img/homepage/Notfoundcar.png"
                    style="width: 500px"
                    alt="No Results Found"
                  />
                </div>
              </div>
              <div class="container">
                <div class="row justify-content-center">
                  <div class="col-12">
                    <div class="pagination-container">
                      <div class="pagination-area">
                        <div aria-label="Page navigation example">
                          <ul class="pagination justify-content-center">
                            <li
                              class="page-item"
                              :class="{
                                disabled: this.currentPage === 1,
                              }"
                            >
                              <a
                                class="page-link"
                                href="#"
                                aria-label="Previous"
                                @click="prevPage"
                              >
                                <span aria-hidden="true">&laquo;</span>
                              </a>
                            </li>
                            <li
                              v-for="page in displayedPages"
                              :key="page"
                              :class="{ active: page === currentPage }"
                            >
                              <a
                                class="page-link"
                                href="#"
                                @click="changePage(page)"
                                >{{ page }}</a
                              >
                            </li>
                            <li
                              class="page-item"
                              :class="{
                                disabled: this.currentPage === totalPages,
                              }"
                            >
                              <a
                                class="page-link"
                                href="#"
                                aria-label="Next"
                                @click="nextPage"
                              >
                                <span aria-hidden="true">&raquo;</span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
    <foote />
  </section>
</template>
<script>
import navigation from "../components/backup";
import foote from "../components/Footer";
import axios from "axios";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css"; // Import the CSS file for vue-select
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import _ from "lodash";

export default {
  data() {
    return {
      someUniqueKey: null,
      isFilterModalActive: false,
      isSortByModalActive: false,
      range: [0, 1000000000],
      kategoriOptions: [],
      selectedKategori: "",
      searchKey: "", // Mengganti searchQuery menjadi searchKey
      results: [],
      isOpen: false,
      currentPage: 1,
      itemsPerPage: 6,
      searchKeyword: "", // Parameter pencarian
      selectedMerek: [],
      selectedJenis: [],
      selectedTransmisi: [],
      selectedBbm: [], // Parameter bbm
      selectedSeat: "", // Parameter seat
      selectedjenis: "", // Parameter listrik
      selectedSortBy: "", // Parameter sort_by
      selectedSortOrder: "", // Parameter sort_order
      merekOptions: [],
      transmisiOptions: [],
      bbmOptions: [],
      seatOptions: [],
      jenisOptions: [],
      sortByOptions: [],
      sortOrderOptions: [],
      isLoading: true,
      defaultImage: "https://auto.jaja.id/img/1.e9ade60d.png",
      searchResults: [], // Store search results

      slides: [
        { src: require("../assets/img/homepage/bj1.jpg") },
        { src: require("../assets/img/homepage/bj2.jpg") },
        // { src: require("../assets/img/homepage/b3.png") },
      ], //Gambar pada banner
      idcar: "", // ID detail produk
    };
  },
  created() {
    const selectedMerek = localStorage.getItem("selectedMerek");
    const selectedTransmisi = localStorage.getItem("selectedTransmisi");
    const selectedBbm = localStorage.getItem("selectedBbm");

    // Check if the retrieved data is valid before parsing
    if (selectedMerek) {
      try {
        this.selectedMerek = JSON.parse(selectedMerek);
      } catch (e) {
        console.error("Error parsing selectedMerek:", e);
      }
    }
    if (selectedTransmisi) {
      try {
        this.selectedTransmisi = JSON.parse(selectedTransmisi);
      } catch (e) {
        // console.error("Error parsing selectedTransmisi:", e);
      }
    }
    if (selectedBbm) {
      try {
        this.selectedBbm = JSON.parse(selectedBbm);
      } catch (e) {
        // console.error("Error parsing selectedBbm:", e);
      }
    }

    this.fetchSearchOptions();

    // Retrieve and parse search results from local storage if available
    const searchResults = localStorage.getItem("searchResults");
    if (searchResults) {
      try {
        this.searchResults = JSON.parse(searchResults);
        console.log("Search results from localStorage:", this.searchResults);
      } catch (e) {
        console.error("Error parsing searchResults:", e);
      }
    }
  },
  computed: {
    formattedRange() {
      return this.range.map((value) => this.formatCurrency(value));
    },
    totalItems() {
      return this.searchResults.length;
    },
    totalPages() {
      return Math.ceil(this.totalItems / this.itemsPerPage);
    },
    displayedResults() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = this.currentPage * this.itemsPerPage;
      return this.searchResults.slice(start, end);
    },
    // Fungsi Ukuran Banner
    calculateHeight() {
      return `${(9 / 27) * this.$vuetify.bfilterCarsreakpoint.width}px`;
    },
    // Fungsi Skeleton Pada Card Produk
    skeletonCount() {
      return this.limit;
    },
    displayedPages() {
      const totalVisiblePages = 3; // Adjust this value based on how many pages you want to display
      const startPage = Math.max(
        1,
        this.currentPage - Math.floor(totalVisiblePages / 2)
      );
      const endPage = Math.min(
        this.totalPages,
        startPage + totalVisiblePages - 1
      );

      const pages = [];
      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }

      return pages;
    },
  },
  mounted() {
    // this.isLoggedIn = localStorage.getItem("token"); //Fungsi Halaman ini Harus Login
    // this.fetchCarItems(); //Fungsi Produk
    this.handledetail(slug); //Fungsi Memanggil ID detail produk

    const searchInput = document.querySelector('input[type="text"]');
    searchInput.addEventListener("input", this.filterCars); //Fungsi Pencarian berdasarkan keyword
  },
  methods: {
    onImageError(event) {
      event.target.src = this.defaultImage;
    },
    // Fungsi debounce untuk menunda pemanggilan permintaan HTTP
    debouncedFetchResults: _.debounce(function () {
      if (this.searchKey.trim() === "") {
        // Jika kueri pencarian kosong, tutup kotak saran
        this.isOpen = false;
        return;
      }

      axios
        .get(
          `https://elasticsearch.masterdiskon.com/jaja-auto/autocomplete-product?q=${this.searchKey}`
        )
        .then((response) => {
          this.results = response.data.data;
          this.isOpen = true;
        })
        .catch((error) => {
          console.error("Error fetching results:", error);
        });
    }, 500), // Setel penundaan debounce menjadi 500 milidetik atau sesuaikan sesuai kebutuhan

    // Panggil debouncedFetchResults saat pengguna berhenti mengetik
    handleInput: function () {
      this.debouncedFetchResults();
    },
    openFilterModal() {
      this.isFilterModalActive = true;
    },
    closeFilterModal() {
      this.isFilterModalActive = false;
    },
    openSortByModal() {
      this.isSortByModalActive = true;
    },
    closeSortByModal() {
      this.isSortByModalActive = false;
    },
    updateMinPrice() {
      this.updateRangeFromFormattedInput(0);
    },
    updateMaxPrice() {
      this.updateRangeFromFormattedInput(1);
    },
    updateRangeFromFormattedInput(index) {
      let value = this.formattedRange[index].replace(/\D/g, ""); // Hapus karakter non-numeric

      // Hapus leading zeros dan konversi ke integer
      const numericValue = parseInt(value) || 0;

      // Bulatkan ke kelipatan 1.000.000
      const roundedValue = Math.round(numericValue / 1000000) * 1000000;

      // Pastikan roundedValue berada dalam rentang yang valid
      const newValue = Math.min(Math.max(0, roundedValue), 1000000000);

      // Update range
      this.$set(this.range, index, newValue);

      // Update formattedRange
      this.$set(this.range, index, newValue);
      // Jika Anda hanya ingin memperbarui nilai yang diubah
      // dan menjaga nilai lainnya tetap sama:
      // this.range[index] = newValue;
      // this.$forceUpdate(); // Jika diperlukan
    },
    formatCurrency(value) {
      // Bulatkan nilai ke kelipatan 1.000.000
      const roundedValue = Math.round(value / 1000000) * 1000000;

      // Format nilai yang dibulatkan ke format mata uang
      const formattedValue = new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR",
        minimumFractionDigits: 0,
      }).format(roundedValue);

      return formattedValue;
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    changePage(page) {
      this.currentPage = page;
    },
    filterCars() {
      // Menggunakan JavaScript filter untuk mencari mobil berdasarkan kata kunci
      this.searchResults = this.searchResults.filter((car) => {
        return car.name
          .toLowerCase()
          .includes(this.searchKeyword.toLowerCase());
      });
    },
    async searchCarsAndUpdateURL() {
      try {
        // Simpan selectedMerek, selectedTransmisi, dan selectedJenis di localStorage
        localStorage.setItem(
          "selectedMerek",
          JSON.stringify(this.selectedMerek)
        );
        localStorage.setItem(
          "selectedTransmisi",
          JSON.stringify(this.selectedTransmisi)
        );
        localStorage.setItem("selectedBbm", JSON.stringify(this.selectedBbm));
        localStorage.setItem(
          "selectedJenis",
          JSON.stringify(this.selectedJenis)
        );

        // Buat objek params untuk menyimpan parameter pencarian
        const params = {
          brand: this.selectedMerek
            ? this.selectedMerek.map((item) => item).join(",")
            : "",
          transmisi: this.selectedTransmisi
            ? this.selectedTransmisi.join(",")
            : "",
          bbm: this.selectedBbm ? this.selectedBbm.join(",") : "",
          seat: this.selectedSeat ? this.selectedSeat.join(",") : "",
          sort_by: this.selectedSortBy ? this.selectedSortBy : "",
          sortPrice: this.selectedSortOrder ? this.selectedSortOrder : "",
          jenis: this.selectedJenis ? this.selectedJenis.join(",") : "",
          kategori_name: this.selectedKategori
            ? this.selectedKategori.join(",")
            : "",
          search: this.searchKey,
          minPrice: Math.round(this.range[0] / 100000) * 100000,
          maxPrice: Math.round(this.range[1] / 100000) * 100000,
        };

        // Setel query params dan navigasi ke halaman pencarian
        this.$router.replace({
          path: "/search",
          query: params,
        });

        // Tutup modal Sort By jika sedang terbuka
        if (this.isSortByModalActive) {
          this.closeSortByModal();
        }

        if (this.isFilterModalActive) {
          this.closeFilterModal();
        }

        // Lakukan pencarian
        await this.searchCars();
      } catch (error) {
        console.error(
          "Terjadi kesalahan dalam memperbarui URL pencarian:",
          error
        );
      }
    },

    async searchCars() {
      const params = {
        brand: this.selectedMerek ? this.selectedMerek.join(",") : "",
        transmisi: this.selectedTransmisi
          ? this.selectedTransmisi.join(",")
          : "",
        bbm: this.selectedBbm ? this.selectedBbm.join(",") : "",
        seat: this.selectedSeat ? this.selectedSeat : "",
        sort_by: this.selectedSortBy ? this.selectedSortBy : "",
        sortPrice: this.selectedSortOrder ? this.selectedSortOrder : "",
        jenis: this.selectedJenis ? this.selectedJenis.join(",") : "",
        kategori_name: this.selectedKategori
          ? this.selectedKategori.join(",")
          : "",
        search: this.searchKey,
        minPrice: Math.round(this.range[0] / 100000) * 100000,
        maxPrice: Math.round(this.range[1] / 100000) * 100000,
      };

      const apiUrl = `https://staging-api.jaja.id/product/get-product?${new URLSearchParams(
        params
      )}`;
      const headers = {
        Authorization: `Bearer ${process.env.VUE_APP_API_TOKEN}`,
      };

      try {
        const response = await fetch(apiUrl, { headers });
        const data = await response.json();

        if (response.ok) {
          this.searchResults = data.data;

          // Save search results and selected filters to local storage
          localStorage.setItem("searchResults", JSON.stringify(data.data));
          localStorage.setItem(
            "selectedMerek",
            JSON.stringify(this.selectedMerek)
          );
          localStorage.setItem(
            "selectedTransmisi",
            JSON.stringify(this.selectedTransmisi)
          );
          localStorage.setItem("selectedBbm", JSON.stringify(this.selectedBbm));

          // Check current query parameters
          const currentQuery = this.$route.query;

          // Check if the new query parameters are different from the current ones
          const isSameQuery = Object.keys(currentQuery).every(
            (key) => params[key] === currentQuery[key]
          );

          // If parameters are different, replace the route query parameters
          if (!isSameQuery) {
            // Perform navigation only if query parameters are different
            await this.$router.replace({ query: params });
          }

          // Scroll to the top after redirect
          window.scrollTo({ top: 0, behavior: "smooth" });
        } else {
          console.error("Failed to fetch search results from API");
        }
      } catch (error) {
        console.error("Error fetching search results:", error);
      }
    },

    async fetchSearchOptions() {
      const apiUrl = "https://staging-api.jaja.id/product/get-select-option";
      const headers = {
        Authorization: `Bearer ${process.env.VUE_APP_API_TOKEN}`,
      };

      try {
        const response = await fetch(apiUrl, { headers });
        const data = await response.json();

        if (data.status.code === 200) {
          // Atur isLoading ke false setelah menerima data
          setTimeout(() => {
            this.kategoriOptions = data.data.kategori_option;
            this.merekOptions = data.data.merek_option;
            this.transmisiOptions = data.data.transmisi_option;
            this.bbmOptions = data.data.bbm_option;
            this.seatOptions = data.data.seat_option;
            this.jenisOptions = data.data.jenis_option;
            this.sortByOptions = data.data.sort_by;
            this.sortOrderOptions = data.data.price_option;
            this.isLoading = false; // Setelah data diterima, isLoading disetel ke false
          }, 2000); // Timeout selama 2 detik
        } else {
          console.error("Gagal mengambil data dari API");
        }
      } catch (error) {
        console.error("Terjadi kesalahan dalam mengambil data:", error);
      }
    },

    // Fungsi Titik Pada Harga
    formatPrice(price) {
      const formatter = new Intl.NumberFormat("id-ID");
      return formatter.format(price);
    },
    // Fungsi Mengambil ID detail
    handledetaillates(carslug) {
      this.slug = carslug;
      console.log("slugss", this.slug);
      // Simpan c.slug di local storage
      localStorage.setItem("slug", carslug);

      // Redirect ke halaman "detaillates" dengan slug dalam URL
      this.$router.push(`/detailview/${carslug}`);
    },
  },

  components: {
    navigation,
    foote,
    vSelect,
    "el-select": ElementUI.Select,
    "el-option": ElementUI.Option,
  },
};
</script>

<style>
/* Gaya untuk modal */
/* CSS for modal */
/* CSS untuk merapikan tampilan hasil pencarian */
.search-results {
  z-index: 999999999999;
  list-style-type: none;
  padding: 0;
  margin: 0;
  position: absolute;
  background-color: white;
  width: 560px;
  border-radius: 8px;
  padding-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 20px;
  height: 200px; /* Tambahkan ketinggian untuk membuat scroll jika lebih dari tinggi maksimum */
  overflow-y: auto; /* Tambahkan scrollbar vertikal jika konten melebihi ketinggian maksimum */
}
/* CSS untuk scrollbar */
.search-results::-webkit-scrollbar {
  width: 8px; /* Lebar scrollbar */
}

.search-results::-webkit-scrollbar-track {
  background: #f1f1f1; /* Warna latar belakang dari track scrollbar */
}

.search-results::-webkit-scrollbar-thumb {
  background: #cbcbcb; /* Warna dari thumb scrollbar */
  border-radius: 4px; /* Rounding dari thumb scrollbar */
}

.search-results::-webkit-scrollbar-thumb:hover {
  background: #cbcbcb; /* Warna thumb scrollbar saat dihover */
}

.search-results li {
  padding: 10px; /* Tambahkan padding untuk ruang di sekitar setiap item */
  cursor: pointer; /* Tambahkan pointer cursor saat item dihover */
  transition: background-color 0.3s; /* Efek transisi saat item dihover */
}

.search-results li:hover {
  background-color: #f0f0f0; /* Warna latar belakang saat item dihover */
}

/* Gaya teks */
.search-results li span {
  font-weight: bold; /* Teks tebal untuk nama dan merek */
}

.search-results li .brand {
  color: #666; /* Warna teks untuk merek */
}

.search-results li .bbm {
  color: #999; /* Warna teks untuk BBM */
}
.modal {
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  align-items: center;
  justify-content: center;
}

.modal.is-active {
  display: flex;
}

.modal-background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.modal-content {
  background-color: #fbab44;
  max-width: 80vw; /* Adjust as needed */
  max-height: 86vh; /* Adjust as needed */
  overflow-y: auto; /* Enable scrolling if content exceeds modal height */
}

.modal-close {
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  font-size: 24px;
  color: black;
}
/* Gaya untuk tombol filter */
.filter-button,
.sort-button {
  width: 100%;
  display: flex;
  height: 39px;
  padding: 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 5px;
  border: 1px solid #207ace;
  background: #fff;
}
@media only screen and (min-width: 440px) {
  .nav-men {
    padding-top: 12%;
  }
}
.pagination-container {
  display: flex;
  justify-content: center;
  margin-top: 20px; /* Adjust the margin based on your design */
}

.vs--searchable .vs__dropdown-toggle {
  height: 50px;
}
.custom-dropdown .dropdown-menu {
  max-height: 60px; /* Sesuaikan tinggi maksimal yang Anda inginkan */
  overflow-y: auto;
}
.col-lg-4 .form-group .v-select .dropdown-toggle::after {
  transform: scaleY(0.5); /* Sesuaikan skala sesuai keinginan Anda */
}

.v-select-list {
  max-height: 200px; /* Set the maximum height for the dropdown */
  overflow-y: auto; /* Enable vertical scrolling */
}

.btn-detail {
  margin-left: -1px;
  margin-top: 10px;
  font-size: 16px;
  width: 100%;
  height: auto;
  color: #207ace;
  padding: 7px 10px;
  transition: all 0.5s;
  text-transform: capitalize;
  position: relative;
  border-radius: 7px;
  font-weight: 500;
  cursor: pointer;
  background-color: white;
  text-align: center;
  overflow: hidden;
  z-index: 1;
  border: 2px solid #207ace; /* Menambahkan border dengan warna biru yang sama */
}
.btn-detail:hover {
  background-color: #207ace; /* Warna latar belakang berubah menjadi biru saat dihover */
  color: white; /* Warna teks berubah menjadi putih saat dihover */
}
.btn-find {
  font-size: 16px;
  color: var(--color-white);
  padding: 5px 5px;
  transition: all 0.5s;
  text-transform: capitalize;
  height: 49px;
  width: 100%;
  position: relative;
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
  text-align: center;
  overflow: hidden;
  border: none;
  background: var(--theme-color);
  box-shadow: var(--box-shadow);
  z-index: 1;
}
.btn-find:hover {
  background: #fbab44;
  color: black;
}
.title-brand {
  margin-bottom: 25px;
  padding-bottom: 10px;
  font-size: 20px;
  position: relative;
  color: #fbab44;
}
</style>
