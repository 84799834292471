<template>
  <div>
    <!-- Navbar Website -->
    <div class="d-none d-lg-block">
      <nav id="navbar" class="navbar">
        <div class="container">
          <div class="navbar-logo">
            <router-link :to="'/'">
              <img
                src="../assets/img/foot/logo-putih.png"
                style="width: 180px; height: 150px; flex-shrink: 0"
                alt="logo"
              />
            </router-link>
          </div>
          <ul class="navbar-links">
            <li>
              <router-link :to="'/'" class="nav-link" @click="scrollToTop"
                ><a class="text-nav">Beranda</a></router-link
              >
            </li>
            <li>
              <router-link
                :to="'/tentang'"
                class="nav-link"
                @click="scrollToTop"
                ><a class="text-nav">Tentang</a></router-link
              >
            </li>
            <li @click="searchCars">
              <a class="text-nav" style="cursor: pointer">Mobil</a>
            </li>
            <li>
              <a
                href="https://play.google.com/store/apps/details?id=com.jajaauto.user"
                target="_blank"
                class="text-nav"
                >Dapatkan Aplikasi</a
              >
            </li>
          </ul>
          <!-- <div class="navbar-logo" href="#">
            <button @click="goToTestDriveForm" class="btn-drive">
              <a class="text-drive"> Test Drive </a>
            </button>
          </div> -->
          <div class="navbar-logo">
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-width="200"
      offset-y
      transition="scale-transition"
      class="white-menu"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="selectedWilayahName"
          label="Pilih Wilayah"
          prepend-icon="mdi-map-marker"
          readonly
          v-bind="attrs"
          v-on="on"
          class="white-label"
        ></v-text-field>
      </template>
      <v-card>
        <v-card-text>
          <v-text-field
            v-model="search"
            prepend-icon="mdi-magnify"
            label="Cari Wilayah"
            single-line
            hide-details
            class="white-label"
          ></v-text-field>
          <v-list
            style="max-height: 200px; overflow-y: auto;"
          >
            <v-list-item
              v-for="wilayah in filteredWilayahOptions"
              :key="wilayah.id"
              @click="selectWilayah(wilayah)"
            >
              <v-list-item-content>
                <v-list-item-title>{{ wilayah.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="filteredWilayahOptions.length === 0">
              <v-list-item-content>
                <v-list-item-title>Tidak ada hasil</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-menu>
  </div>
        </div>
      </nav>
    </div>
    <!-- Navbar Mobile -->
    <div class="d-lg-none" style="background-color: #1f1c1c">
      <div style="padding: 20px" class="d-flex justify-content-between">
        <div>
          <router-link :to="'/'">
            <img
              src="../assets/img/navbar/mobile/logo.png"
              style="width: 120px"
              alt=""
            />
          </router-link>
        </div>
        <div></div>
        <div class="mt-2">
          <img
            src="../assets/img/navbar/mobile/harmburger.png"
            style="width: 20px"
            alt=""
            @click="toggleDropdown"
          />
          <div v-if="showDropdown" class="dropdown1">
            <!-- Daftar pilihan dropdown -->
            <ul>
              <li><router-link :to="'/'">Beranda </router-link></li>
              <li @click="searchCars">Katalog</li>
              <li><router-link :to="'/tentang'">Tentang Kami</router-link></li>
              <li>
                <router-link :to="'/testDriveForm'">Test Drive</router-link>
              </li>

              <!-- Tambahkan lebih banyak menu sesuai kebutuhan -->
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Swal from "sweetalert2";

export default {
  data: () => ({
    menu: false, // Model untuk v-menu
      search: '', // Model untuk pencarian
      selectedWilayah: null, // Model untuk wilayah yang dipilih
      selectedWilayahName: '', // Nama wilayah yang ditampilkan
      wilayahOptions: [
        { id: 1, name: 'Jakarta' },
        { id: 2, name: 'Bandung' },
        { id: 3, name: 'Surabaya' },
        { id: 4, name: 'Medan' },
        { id: 5, name: 'Makassar' },
        { id: 6, name: 'Semarang' },
        { id: 7, name: 'Yogyakarta' },
        { id: 8, name: 'Palembang' },
        { id: 9, name: 'Denpasar' },
        { id: 10, name: 'Balikpapan' },
        // Tambahkan lebih banyak wilayah sesuai kebutuhan
      ],
    searchKey: "", 
    isScrolled: false,
    hoveredKategoriId: null,
    selectedKategoriId: null, // Menyimpan ID kategori yang dipilih
    selectedMerekId: null, // Menyimpan ID merek yang dipilih
    showDropdown: false,
    showSelectedMerkData: false,
    searchResults: [],
    selectedMerek: "",
    kategoris: [],
    kategoriData: [],
    selectedMerkData: [],
    selectedKategori: "",
    currentRoute: "",
    showSearch: false,
    isMenuActive: false,
    carItems: [], // Daftar mobil dari response API
    filteredCarItems: [], // Daftar mobil yang sudah difilter
    uniqueCarTypes: [], // Fungsi Pencarian Berdasarkan Brand
    filteredCarModels: [], // Fungsi Pencarian Berdasarkan Model
    selectedCarType: "", // Opsi yang dipilih untuk Car Brand
    selectedCarModel: "", // Opsi yang dipilih untuk Car Models
    selectedPriceRange: "", // Opsi yang dipilih untuk Price Range
    isLoading: false, // Loading pada filter
    backgroundImage: require("@/assets/img/homepage/white.jpg"), //Image pada background image navbar
    visible: false, //Fungsi untuk mengaktifkan/ menonaktifkan login dan logout
    drawer: null, //drawer untuk responsive navbar
    hover: false, //responsive navbar
    isModalOpen: false,
    isButtonClicked: false,
    isXs: false, //ukuran pada drawer
    items: [
      ["mdi-home-outline", "Home", "/"],
      ["mdi-car", "Product", "/kategori"],
      ["mdi-ballot", "About Us", "/tentang"],
      // ["mdi-ballot", "FAQ", "/faq"],
    ], //tampilan yang muncul pada drawer
  }),
  props: {
    color: String, //untuk mengatur warna komponen
    flat: Boolean, //untuk mengatur apakah komponen memiliki tampilan datar atau tidak
  },
  mounted() {
    // this.isLoggedIn = localStorage.getItem("token"); //megambil token
    // this.checkLoginStatus(); // Cek status login saat komponen dimuat
    this.fetchCarItems(); //Fungsi Produk
    window.addEventListener("scroll", this.handleScroll);
  },
  created() {
    this.currentRoute = this.$route.path;
  },
  watch: {
    $route(to, from) {
      this.currentRoute = to.path;
    },
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  computed: {
    filteredWilayahOptions() {
      if (this.search.trim() === '') {
        return this.wilayahOptions;
      }
      return this.wilayahOptions.filter(wilayah =>
        wilayah.name.toLowerCase().includes(this.search.toLowerCase())
      );
    },
  },
  methods: {
    selectWilayah(wilayah) {
      this.selectedWilayah = wilayah.id;
      this.selectedWilayahName = wilayah.name;
      this.menu = false;
      console.log('Wilayah yang dipilih:', wilayah);
      // Implementasikan logika yang diperlukan setelah memilih wilayah
      this.goToTestDriveForm(wilayah);
    },
    goToTestDriveForm(wilayah) {
      if (wilayah) {
        // Contoh navigasi menggunakan Vue Router
        this.$router.push({ 
          name: 'TestDriveForm', 
          params: { wilayahId: wilayah.id, wilayahName: wilayah.name } 
        });
      } else {
        // Jika wilayah dihapus atau tidak dipilih
        console.log('Wilayah tidak dipilih');
      }
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    goToTestDriveForm() {
      this.$router.push({ path: "/testDriveForm" });
    },
    handleScroll() {
      if (window.scrollY > 50) {
        this.isScrolled = true;
      } else {
        this.isScrolled = false;
      }
    },
    toggleDropdown() {
      this.showDropdown = !this.showDropdown; // Mengubah status dropdown saat tombol harmburger diklik
    },
    async searchCars() {
  const params = {
    brand: this.selectedMerek ? this.selectedMerek.join(",") : "",
    transmisi: this.selectedTransmisi ? this.selectedTransmisi.join(",") : "",
    bbm: this.selectedBbm ? this.selectedBbm.join(",") : "",
    seat: this.selectedSeat ? this.selectedSeat : "",
    sort_by: this.selectedSortBy ? this.selectedSortBy : "",
    sort_order: this.selectedSortOrder ? this.selectedSortOrder : "",
    jenis_name: this.selectedJenis ? this.selectedJenis.join(",") : "",
    kategori_name: this.selectedKategori ? this.selectedKategori.join(",") : "",
    search: this.searchKey,
  };

  const apiUrl = `https://staging-api.jaja.id/product/get-product?${new URLSearchParams(params)}`;
  const headers = {
    Authorization: `Bearer ${process.env.VUE_APP_API_TOKEN}`,
  };

  try {
    console.log("Fetching from API:", apiUrl);
    const response = await fetch(apiUrl, { headers });
    const data = await response.json();

    console.log("API Response:", data);

    if (data.data) {
      this.searchResults = data.data;
      localStorage.setItem("searchResults", JSON.stringify(data.data));
      localStorage.setItem("selectedMerek", JSON.stringify(this.selectedMerek));
      localStorage.setItem("selectedTransmisi", JSON.stringify(this.selectedTransmisi));
      localStorage.setItem("selectedBbm", JSON.stringify(this.selectedBbm));

      const currentParams = this.$route.query;
      const sameParams = Object.keys(params).every(
        (key) => params[key] === currentParams[key]
      );

      if (!sameParams) {
        this.$router.push({
          path: "/search",
          query: params,
        });

        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    } else {
      console.error("Unexpected API response structure:", data);
    }
  } catch (error) {
    console.error("Error fetching search results:", error);
  }
},

    async searchCarsAndUpdateURL() {
      await localStorage.setItem(
        "selectedMerek",
        JSON.stringify(this.selectedMerek)
      );
      await localStorage.setItem(
        "selectedTransmisi",
        JSON.stringify(this.selectedTransmisi)
      );
      await localStorage.setItem(
        "selectedJenis",
        JSON.stringify(this.selectedJenis)
      );

      const params = {
        merek: this.selectedMerek
          ? this.selectedMerek.map((item) => item.value).join(",")
          : "",
        transmisi: this.selectedTransmisi
          ? this.selectedTransmisi.map((item) => item.value).join(",")
          : "",
        bbm: this.selectedBbm
          ? this.selectedBbm.map((item) => item.value).join(",")
          : "",
        seat: this.selectedSeat
          ? this.selectedSeat.map((item) => item.value).join(",")
          : "",
        // listrik: this.selectedListrik
        //   ? this.selectedListrik.map((item) => item.value).join(",")
        //   : "",
        sort_by: this.selectedSortBy ? this.selectedSortBy.value : "",
        sort_order: this.selectedSortOrder ? this.selectedSortOrder.value : "",
        jenis_name: this.selectedJenis
          ? this.selectedJenis.map((item) => item.label).join(",")
          : "",
        kategori_name: this.selectedKategori
          ? this.selectedKategori.map((item) => item.label).join(",")
          : "",

        search: this.searchKey,
        // slug_merek: this.selectedMerek ? this.selectedMerek[0].slug : "",
        // jenis_name: this.selectedJenis ? this.selectedJenis[0].value : "",
      };

      this.$router.replace({
        path: "/search",
        query: params,
      });

      await this.searchCars();
    },
    selectKategori(kategori) {
      this.selectedKategoriId = kategori.id; // Setel ID kategori yang dipilih
      this.selectedMerkData = kategori.merek;
      this.showSelectedMerkData = true;
    },
    selectAndSearchBrand(brand) {
      console.log("Selected Brand:", brand);
      this.selectedMerekId = brand.id_merek; // Setel ID merek yang dipilih
      this.selectedMerek = [
        {
          value: brand.nama_merek,
          label: brand.nama_merek,
          slug: brand.slug_merek,
        },
      ];
      this.searchCars();
    },
    // async fetchKategoriData() {
    //   const apiUrl = "https://api.jaja.id/jauto/produk/get_kategori";
    //   const headers = {
    //     Authorization: `Bearer ${process.env.VUE_APP_API_TOKEN}`,
    //   };

    //   try {
    //     const response = await fetch(apiUrl, { headers });
    //     const data = await response.json();

    //     if (data.success) {
    //       this.kategoriData = data.data;
    //     } else {
    //       console.error("Gagal mengambil data kategori dari API");
    //     }
    //   } catch (error) {
    //     console.error(
    //       "Terjadi kesalahan dalam mengambil data kategori:",
    //       error
    //     );
    //   }
    // },
    openModal() {
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },
    showMerek(kategoriId) {
      // Toggle selectedKategori saat kategori diklik
      this.selectedKategori =
        this.selectedKategori === kategoriId ? null : kategoriId;
    },
    async fetchData() {
      try {
        // Pastikan untuk menggantikan process.env.VUE_APP_API_TOKEN dengan token yang benar
        const apiUrl = "https://api.jaja.id/jauto/produk/get_kategori";
        const headers = {
          Authorization: `Bearer ${process.env.VUE_APP_API_TOKEN}`,
        };

        const response = await fetch(apiUrl, { headers });
        const data = await response.json();

        if (data.success) {
          this.kategoris = data.data;
        } else {
          console.error("Gagal mengambil data:", data.message);
        }
      } catch (error) {
        console.error("Terjadi kesalahan:", error);
      }
    },
    handleDetailClick(slug) {
      // Simpan slug di local storage
      localStorage.setItem("slug", slug);

      // Redirect ke halaman "detaillates" dengan slug dalam URL
      // Ganti URL dengan format yang sesuai dengan aplikasi Anda
      this.$router.push(`/detailview/${slug}`);
      localStorage.removeItem("slug");
    },
    showSearchArea() {
      this.showSearch = true;
      $(".nav-right").hide();
    },
    hideSearchArea() {
      this.showSearch = false;
      $(".nav-right").show();
    },
    toggleMenu() {
      this.isMenuActive = !this.isMenuActive;
      // Get the target menu element
      const targetMenu = document.getElementById("main_nav");

      // Ensure targetMenu exists before accessing its classList
      if (targetMenu) {
        // Toggle the 'show' class on the target menu to control its visibility
        targetMenu.classList.toggle("show");
      }
    },
    // fungsi  untuk periksa apakah token tersedia dalam localStorage

    // Fungsi Produk Jaja Auto
    fetchCarItems() {
      this.isLoading = true; // Set isLoading to true before making the request
      axios
        .get("https://api.jaja.id/produk/get", {
          headers: {
            Authorization: `Bearer ${process.env.VUE_APP_API_TOKEN}`,
          },
        })
        .then((response) => {
          const uniqueCarItems = this.removeDuplicateItems(response.data.data);
          this.carItems = uniqueCarItems;
          this.filteredCarItems = [...this.carItems];
          this.updateUniqueCarTypes(); // call the method to update uniqueCarTypes
          console.log("Ini responsenya:", response.data.data);
          console.log("Ini isinya:", this.carItems);
        })
        .catch((error) => console.log(error))
        .finally(() => {
          this.isLoading = false; // Set isLoading to false when the request is complete
        });
    },
    // Fungsi Hapus Data Duplikat
    removeDuplicateItems(items) {
      const uniqueItems = [];
      const seen = new Set();

      for (let item of items) {
        const key = `${item.brand}_${item.model}`;
        if (!seen.has(key)) {
          seen.add(key);
          uniqueItems.push(item);
        }
      }

      return uniqueItems;
    },
    // Fungsi Pencarian Berdasarkan Brand
    updateUniqueCarTypes() {
      this.uniqueCarTypes = Array.from(
        new Set(this.carItems.map((item) => item.brand))
      );
    },
    // Fungsi Pencarian Berdasarkan Model
    updateCarModels() {
      this.filteredCarModels = this.carItems
        .filter((car) => car.brand === this.selectedCarType)
        .map((car) => car.model);
      this.selectedCarModel = ""; // Reset the selectedCarModel when the brand is changed
    },
    // Fungsi Filter
    filterCars() {
      const uniqueCarTypes = new Set();

      this.filteredCarItems = this.carItems.filter((car) => {
        const isBrandMatched =
          this.selectedCarType === "" || car.brand === this.selectedCarType;
        const isModelMatched =
          this.selectedCarModel === "" || car.model === this.selectedCarModel;
        const isPriceRangeMatched =
          this.selectedPriceRange === "" ||
          car.code === this.selectedPriceRange;
        const isSearchMatched =
          !this.carModelSearch ||
          car.model.toLowerCase().includes(this.carModelSearch.toLowerCase());

        if (
          isBrandMatched &&
          isModelMatched &&
          isPriceRangeMatched &&
          isSearchMatched
        ) {
          if (!uniqueCarTypes.has(car.brand)) {
            uniqueCarTypes.add(car.brand);
          }
          return true;
        }

        return false;
      });

      this.$nextTick(() => {
        // Scroll ke elemen dengan ref "resultsContainer"
        const resultsContainer = this.$refs.resultsContainer;
        if (resultsContainer) {
          const topOffset = resultsContainer.offsetTop;
          window.scrollTo({ top: topOffset, behavior: "smooth" });
        }
      });
      // ...
    },

    // fungsi ukuran drawer
    onResize() {
      this.isXs = window.innerWidth < 850;
    },
    onResize() {
      this.isXs = window.innerWidth < 850;
    },
  },
  // Berfungsi untuk perubahan pada properti tersebut dan menjalankan fungsi yang terkait ketika nilai berubah
  watch: {
    isXs(value) {
      if (!value) {
        if (this.drawer) {
          this.drawer = false;
        }
      }
    },
  },
  mounted() {
    // this.fetchKategoriData();
    // this.fetchData();
    this.onResize();

    // Store a reference to "this" for use inside the event listener
    const self = this;

    // Use arrow function to preserve "this" context
    window.addEventListener("scroll", () => {
      var navbar = document.getElementById("navbar");
      var isScrolled = window.scrollY > 0;

      if (navbar) {
        if (isScrolled) {
          navbar.classList.add("navbar-scrolled");
        } else {
          navbar.classList.remove("navbar-scrolled");
        }
      }
    });
  },
};
</script>


<style scoped>
.car-gambar-brand:hover {
  padding: 5px;
  border: 1px solid #207ace; /* Warna dan ketebalan border dapat disesuaikan */
  border-radius: 13px;
  /* height: 100px; */
}

.selected-item {
  border: 1px solid #207ace; /* Warna dan ketebalan border dapat disesuaikan */
  border-radius: 13px;
}

/* Add this to your CSS styles */
.custom-dropdown-menu {
  width: 800px; /* Adjust the width as needed */
}

.modal {
  position: fixed;
  top: 30%;
  left: 70%;
  transform: translate(-30%, -50%);
  z-index: 999;
  max-width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
}

.kategori-merek-container {
  display: flex;
  flex-wrap: wrap;
}

.kategori {
  margin-right: 20px;
}

.merek-container {
  display: flex;
  flex-wrap: wrap;
}

.merek-card {
  margin-right: 10px;
  padding: 10px;
  width: 100px; /* Sesuaikan dengan kebutuhan */
  text-align: center;
}

/* CSS to set the height of the navbar links (nav-link) */
/* Center the text vertically within the navbar links */
.main-navigation .navbar-nav .nav-link {
  display: flex;
  align-items: center;
  line-height: 35px; /* Adjust this value as needed */
  /* Other styles for the navbar links */
}

.fixed-navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
}
.expand {
  height: 80px !important;
  padding-top: 10px;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .logo {
    width: 100px;
    height: 20px;
    margin-top: 40px;
    margin-right: 10px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .logo {
    width: 110px;
    height: 25px;
    margin-top: 40px;
    margin-right: 10px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .logo {
    width: 150px;
    height: 40px;
    margin-top: 50px;
    margin-right: 40px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .logo {
    width: 180px;
    height: 45px;
    margin-top: 50px;
    margin-right: 40px;
  }
}
@media (max-width: 995px) {
  .nav-item.dropdown {
    display: none;
  }
  .garis {
    display: none;
  }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .logo {
    width: 150px;
    height: 42px;
    margin-top: 50px;
    margin-right: 40px;
  }
}
.dropdown1 {
  position: absolute;
  top: 70px; /* Adjust the position according to your layout */
  left: 0;
  background-color: #1f1c1c; /* Dropdown background color */
  border-radius: 5px; /* Optional: Round the corners */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Optional: Dropdown shadow */
  z-index: 1000; /* Ensure the dropdown appears above other content */
  width: 100%;
}

.dropdown1 ul {
  list-style: none;
  padding: 0;
  margin: 0;

  text-align: center;
}

.dropdown1 ul li {
  padding: 10px;
  cursor: pointer;
  color: white;
  border-bottom: 1px solid #fff; /* Optional: Add a border between menu items */
}

.dropdown1 ul li:last-child {
  border-bottom: none; /* Remove border for last menu item */
}

.dropdown1 ul li a {
  color: #fff; /* Menu item text color */
  text-decoration: none;
  display: block;
}

.dropdown1 ul li:hover {
  background-color: #005d8f; /* Hover background color */
}
.btn-drive {
  border-radius: none;
  display: flex;
  padding: 12px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border: 1px solid #fff;
  height: 50px;
}
.text-drive {
  color: #fff;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.48px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.text-nav {
  color: var(--Color-Neutral-Neutral-00, #fff);
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.48px;
}
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  transition: background-color 0.3s ease;
  background: linear-gradient(180deg, #1d1e1f -45%, rgba(25, 25, 26, 0) 100%);
}

.navbar-scrolled {
  background-color: #1f1c1c;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
  height: 100px;
}

.navbar-logo {
  font-size: 24px;
  font-weight: bold;
  color: #fff;
  text-decoration: none;
}

.navbar-links {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.navbar-links li {
  display: inline-block;
  margin-right: 60px;
}

.navbar-links li a {
  color: #fff;
  text-decoration: none;
  transition: color 0.3s ease;
}

.navbar-links li a:hover {
  color: #ccc;
}
.container-baru {
  padding-left: 100px;
  padding-right: 100px;
}

.white-menu {
  /* Styling tambahan untuk v-menu jika diperlukan */
}
.white-label >>> .v-label { color: white !important; }
.white-label >>> .v-input__slot input { color: white !important; }
.white-label >>> .v-input__prepend-inner .v-icon { color: white !important; }
.white-menu >>> .v-list-item-title { color: white !important; }
.white-label >>> .v-input__slot::before,
.white-label >>> .v-input__slot::after,
.white-menu >>> .v-input__slot::before,
.white-menu >>> .v-input__slot::after {
  border-bottom: 1px solid white !important;
}
.white-label >>> .v-input__slot input::placeholder,
.white-menu >>> .v-input__slot input::placeholder {
  color: rgba(255, 255, 255, 0.7) !important;
}
.white-label >>> .v-input__slot input,
.white-menu >>> .v-input__slot input {
  caret-color: white !important;
}
.white-menu >>> .v-card {
  background-color: #333 !important;
}
.white-menu >>> .v-list-item {
  background-color: #333 !important;
}
.white-menu >>> .v-list-item:hover {
  background-color: #444 !important;
}
/* Menambahkan aturan untuk ikon dalam dropdown jika ada */
.white-menu >>> .v-list-item-icon {
  color: white !important;
}

</style>
